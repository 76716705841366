<template>
  <div class="container container-table">
    <div class="columns is-centered is-multiline">
      <div class="container column is-8">
        <div style="max-width:6em; margin: 0 auto;">
          <a href="/" class="logo-mini">
            <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo">
          </a>
        </div>
      </div>
      <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->

      <div class="column is-8">
        <div class="box box-info" style="color:black">
          <div class="box-header content with-border">
            <div class="has-text-centered">
              <h1>{{ this.$route.name.toUpperCase() }}</h1>
            </div>
            <div class="center-block" style="width: fit-content; text-align: center">
              <img src="/img/logo.png" class="inline-block sublogo">
              <img
                v-if="$route.query.locale == 'en_US'"
                src="/img/math-shed-logo-dark.png"
                class="inline-block sublogo mathslogo"
              >
              <img v-else src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo">
            </div>
          </div>
          <div v-if="inviteData" class="box-body" style="width:100%">
            <p>You have been invited to join <b>{{ inviteData.school.school_name }}</b> as a teacher.</p>
            <p>&nbsp;</p>
            <div>
              <article class="message is-warning is-full-width">
                <div class="message-header">
                  <p>Join <b>{{ inviteData.school.school_name }}</b>?</p>
                </div>
                <div class="message-body">
                  <p>You have been invited to join the school account of <b>{{ inviteData.school.school_name }}</b>.</p>
                  <p>Enter your password and click "Accept" to accept this invitation.</p>
                  <hr>
                  <form>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label">Password</label>
                      </div>
                      <div class="field-body">
                        <div class="field has-addons has-addons-right">
                          <p class="control is-expanded has-icons-left">
                            <input v-model="password" class="input" type="password" placeholder="Your password">
                            <span class="icon is-small is-left">
                              <i class="fas fa-lock" />
                            </span>
                          </p>
                          <p class="control">
                            <button class="button is-success" @click.prevent="acceptInvite()">
                              Accept
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </article>
            </div>
          </div>

          <footer class="row">
            <div class="container">
              <div class="text-center col-md-4 col-sm-offset-4">
                <p style="font-size:14px; color: #4a4a4a;">
                  Copyright &copy; {{ new Date().getFullYear() }} Education Shed
                  Ltd.
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'

export default {
  name: 'TeacherInvite',
  data (router) {
    return {
      inviteData: '',
      password: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkCode()
    })
  },
  methods: {
    checkCode () {
      console.log(this.$route.query.code)
      request('GET', 'users/teacherinvite?code=' + this.$route.query.code, null)
        .then((response) => {
          const data = response.data
          if (data.error || !data.invite) {
            alert(data.error || 'Error')
          }
          this.inviteData = data.invite
        })
        .catch((error) => {
          console.log(error)

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    acceptInvite () {
      const c = confirm('Are you sure? This cannot be undone.')
      if (c) {
        request('POST', 'users/teacherinvite', { code: this.$route.query.code, password: this.password })
          .then((response) => {
            const data = response.data
            if (data.error) {
              alert(data.error)
            } else {
              alert('Success')
              this.$router.push('/login')
            }
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    }
  }
}
</script>
<style scoped>
  p {
    margin: 8px 0;
  }
</style>
